import * as THREE from 'three'
import React, { useMemo, useState, useContext } from "react";
import { Verbiage } from './Verbiage';
import { AppCtx, reducer } from "../MainPage"


export const MeditationBtn = (arg:any) => {
  
  const base: any = new THREE.Shape()
                      .absarc( -18, 0, 5, 3*Math.PI/2, 1 * Math.PI/2, true )
                      .absarc( 18, 0, 5, 1 * Math.PI/2, 3 * Math.PI/2, true );
  const color = "#ffffff";
  const [isHovered, setIsHovered] = useState(false);
  const appContext = useContext(AppCtx);
  
  const calculatedColor = useMemo(() => {
    if (isHovered) {
      return "lightblue";
    }
    return color;
  }, [color, isHovered]);

    return (
      <mesh position={[0,0,0]}
        onPointerOver={() => setIsHovered(true)}
        onPointerLeave={() => setIsHovered(false)} 
        onClick={() => {
          if(appContext?.state.cardFlip[arg.index]){
            appContext?.dispatch({type:"startMeditation"});
          }
        }}>
        <shapeBufferGeometry attach="geometry" args={[base]} />
        <meshBasicMaterial transparent color={calculatedColor} side={THREE.DoubleSide}/>
        <mesh position={[0,0,1]}>
            <Verbiage input={{text:"瞑想をはじめる", fontSize:5, color:"#000000"}} />
          </mesh>
      </mesh>
    );
  };
  