import React, { useState } from "react";
import ReactDOM from "react-dom";
import { extend, Canvas } from "react-three-fiber";
import { Text } from "troika-three-text";
import { TrackBtn } from "./TrackBtn";
import { Verbiage } from "./Verbiage";

extend({ Text });

const title = "心と体を整える";
const verbiage =
  "心をリラックスさせましょう。\nたった5分でできる360°没入型瞑想で一息つきませんか?";

export const BoardTop = () => {
  return (
    <>
      <mesh position={[0, 0, 0]}>
        <Verbiage input={{ text: title, fontSize: 40, color: "#ffffff" }} />
      </mesh>
      <mesh position={[0, -150, 0]}>
        <Verbiage
          input={{
            text: verbiage,
            fontSize: 30,
            color: "#ffffff",
            width: 1000,
          }}
        />
      </mesh>
      <mesh position={[0, -300, 0]}>
        <TrackBtn />
      </mesh>
    </>
  );
};
