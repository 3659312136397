import * as THREE from 'three'

const Cylinder = () => {
  
    return (
      <mesh>
        <cylinderBufferGeometry attach="geometry" args={[400, 420, 1600, 32, 1, false, 0, Math.PI *2]}   />
        <meshStandardMaterial color={0x000000}  side={THREE.DoubleSide}/>
      </mesh>
    );
  };
  
export default Cylinder;
