import * as THREE from 'three'
import React, { useMemo, useState, useContext } from "react";
import { Verbiage } from './Verbiage';
import { AppCtx, reducer } from "../MainPage"


export const TrackBtn = () => {
  
  const base: any = new THREE.Shape()
                      .absarc( -100, 0, 20, 3*Math.PI/2, 1 * Math.PI/2, true )
                      .absarc( 100, 0, 20, 1 * Math.PI/2, 3 * Math.PI/2, true );
  const color = "#FFFFFF";
  const [isHovered, setIsHovered] = useState(false);
  const appContext = useContext(AppCtx);
  
  const calculatedColor = useMemo(() => {
    if (isHovered) {
      return "lightblue";
    }
    return color;
  }, [color, isHovered]);

    return (
      <>
        <mesh position={[0,0,0]}
        onPointerOver={() => setIsHovered(true)}
        onPointerLeave={() => setIsHovered(false)} 
        onClick={() => {
            appContext?.dispatch({type:"init"});
        }}>
          <shapeBufferGeometry attach="geometry" args={[base]} />
          <meshBasicMaterial transparent color={calculatedColor} side={THREE.DoubleSide}/>
          <mesh position={[0,0,10]}>
              <Verbiage input={{text:"瞑想をはじめる", fontSize:20, color:"#000000"}} />
            </mesh>
        </mesh>
      </>
    );
  };
  