import { Canvas , useFrame, useThree } from "react-three-fiber";
import { Billboard, OrbitControls, PositionalAudio } from "@react-three/drei";
import { World } from './components/World'
import { createContext, useReducer , useEffect, useRef, useContext, useState }  from "react";
import Button from '@mui/material/Button';
import { yellow } from '@mui/material/colors';

const color = yellow[500];

type InitialStateType = {
  init: boolean;
  movie: string;
  cardFlip: Array<boolean>;
  meditation: boolean;
  form: boolean;
}
interface AppContextInterface {
  state: InitialStateType;
  dispatch: React.Dispatch<any>;
}

if (window.location.pathname === "/thankyou") {
  setTimeout(() => {
    window.location.href = "/form";
  }, 3 * 1000);
}

export const AppCtx = createContext<AppContextInterface | null>(null);

const INIT = 'init' as const;
const SET_MOVIE = 'setMovie' as const;
const CARD_FLIP = 'cardFlip' as const;
const MEDITATION = 'startMeditation' as const;
const FORM = 'form' as const;

const boolArray = [false, false, false, false, false, false, false, false, false, false, false, false];

export const initMeditation = () => ({
  type: INIT,
});

export const setMovie = (file: string) => ({
  type: SET_MOVIE,
  payload: {
    file: file,
  },
});

export const startMeditation = () => ({
  type: MEDITATION,
});

export const startForm = () => ({
  type: FORM,
});

export const cardFlip = (index: number) => ({
  type: CARD_FLIP,
  payload: {
    index: index,
  }
});


const initialState: InitialStateType = {
  init: false,
  movie: '/scene0.mp4',
  cardFlip: Array.from(boolArray),
  meditation: false,
  form: false,
};

type ACTION_TYPE = (
  | ReturnType<typeof initMeditation>
  | ReturnType<typeof setMovie>
  | ReturnType<typeof cardFlip>
  | ReturnType<typeof startMeditation>
  | ReturnType<typeof startForm>
);


export const reducer = (state: InitialStateType, action: ACTION_TYPE) => {
  switch(action.type){
    case null:
      return { ...state };
    case INIT:
      return { ...state, init: true };
    case SET_MOVIE:
      return { ...state, movie: action.payload.file };
    case CARD_FLIP:
      let cardState = Array.from(boolArray);
      cardState[action.payload.index] =true
      return { ...state, cardFlip: cardState };  
    case MEDITATION:
      return { ...state, meditation: true };
    case FORM:
      return { ...state, form: true };
    default:
      return { ...state };
  }
};

const MainPage = () => {

  const [display, setDisplay] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);

  if (state.meditation) {
    setTimeout(() => {
      setDisplay(true);
    }, 10 * 1000);
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    console.log(event);
    forwardForm();
  };

  return (
    <>
    <div style={{
      position: "absolute",
      top: "10px",
      right: "10px",
      zIndex: 100,
      display: display? "":"none",
    }}>
        <Button color="primary" variant="contained" onClick={(e) => handleClick(e)}>瞑想を終える</Button>
    </div>
    <div
    style={{
      position: "absolute",
      top: 0,
      height: "100vh",
      width: "100vw",
    }}
    >
      <Canvas camera={{ fov: 80, position: [0, 0, 180]}} >
        {/* <CameraController /> */}
        <ambientLight color={"white"} intensity={2.1} />
        <OrbitControls minPolarAngle={Math.PI / 3} maxPolarAngle={Math.PI *2/3} minDistance={1} maxDistance={380} />

        <AppCtx.Provider value={{state, dispatch}} >
          <World />
        </AppCtx.Provider>

      </Canvas>
    </div>
    </>
  );
}

function forwardForm() {
  setTimeout(() => {
    window.location.href = "/form";
  }, 3 * 1000);
}

export default MainPage;
