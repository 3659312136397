import React, { useState, useMemo, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { extend, Canvas, useFrame } from "react-three-fiber";
import { Text } from "troika-three-text";
import { TrackBtn } from "./TrackBtn";
import { Verbiage } from "./Verbiage";
import * as THREE from "three";
import { BoxWithRoundedEdges } from "./BoxWithRoundedEdges";
import { BoxGeometry } from "three";
import { AppCtx, reducer } from "../MainPage";
import { useRef } from "react";
import { Color, useLoader } from "react-three-fiber";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { MeditationBtn } from "./MeditationBtn";

extend({ Text });

export const BoardScene = (arg) => {
  const appContext = useContext(AppCtx);
  const meshRef = useRef();

  const ref = {
    width: 50,
    height: 100,
    depth: 1,
    radius0: 1,
    smoothness: 2,
    image: arg.image,
    color: arg.color,
  };

  useFrame(() => {
    if (rotate) {
      if (meshRef.current.rotation.y <= Math.PI)
        meshRef.current.rotation.y += 0.3;
      if (meshRef.current.rotation.y > Math.PI)
        meshRef.current.rotation.y = Math.PI;
    } else {
      if (meshRef.current.rotation.y >= 0) meshRef.current.rotation.y -= 0.3;
      if (meshRef.current.rotation.y < 0) meshRef.current.rotation.y = 0;
    }

    if (meditation) {
      if (meshRef.current.scale.x > 0) {
        meshRef.current.scale.x -= 0.05;
        meshRef.current.scale.y -= 0.05;
        meshRef.current.scale.z -= 0.05;
      }
      if (meshRef.current.position.z < 600) {
        meshRef.current.position.z += 5;
      }
    }
  });

  const [meditation, setMeditation] = useState(false);
  const [rotate, setRotate] = useState(false);

  useEffect(() => {
    setRotate(appContext?.state.cardFlip[arg.index]);
  }, appContext?.state.cardFlip);

  useEffect(() => {
    setMeditation(appContext?.state.meditation);
  }, [appContext?.state.meditation]);

  return (
    <mesh
      scale={[2, 2, 2]}
      ref={meshRef}
      // onPointerOver={() => setIsHovered(false)}
      // onPointerLeave={() => setIsHovered(false)}
    >
      <mesh
        onClick={() => {
          if (!rotate) {
            setRotate(true);
            appContext?.dispatch({
              type: "setMovie",
              payload: {
                file: arg.movie,
              },
            });
            appContext?.dispatch({
              type: "cardFlip",
              payload: {
                index: arg.index,
              },
            });
          }
        }}
      >
        <BoxWithRoundedEdges {...ref} />
      </mesh>
      <mesh>
        <mesh position={[0, -25, 3]}>
          <Verbiage
            input={{
              text: arg.title,
              fontSize: 6,
              color: "#ffffff",
            }}
          />
        </mesh>
        <mesh position={[0, -35, 3]}>
          <Verbiage
            input={{
              text: arg.verbiage,
              fontSize: 6,
              color: "#ffffff",
              width: 10,
            }}
          />
        </mesh>
      </mesh>
      <mesh position={[0, 0, -3]} rotation={[0, Math.PI, 0]}>
        <mesh position={[0, 20, 0]}>
          <Verbiage
            input={{
              text: arg.description,
              fontSize: 4,
              color: "#ffffff",
              width: 47,
              align: arg.align,
            }}
          />
        </mesh>
        <mesh position={[0, -35, 0]}>
          <MeditationBtn {...arg} />
        </mesh>
      </mesh>
    </mesh>
  );
};
