import { extend } from "react-three-fiber";
import { Text } from "troika-three-text";
import fonts from "./fonts";

extend({ Text });

export const Verbiage = (args) => {
  // State:
  const opts = {
    font: fonts["J1"],
    fontSize: args.input.fontSize == null ? 10 : args.input.fontSize,
    color: args.input.color == null ? "#ffffff" : args.input.color,
    maxWidth: args.input.width == null ? 100 : args.input.width,
    lineHeight: 0,
    letterSpacing: 0,
    textAlign: args.input.align == null ? "center" : args.input.align,
    materialType: "MeshBasicMaterial",
    anchorX: "center",
    anchorY: "middle",
  };

  return (
    <mesh position={[0, 0, 0]} rotateZ={[5]}>
      <text position-z={0} {...opts} text={args.input.text} />
    </mesh>
  );
};
