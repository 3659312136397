import { Vector3, Euler } from "react-three-fiber";
import { Color } from "three";
import { BoardScene } from "./BoardScene"

const Cards = () => {

  const r: number = 350;

  let positions: Vector3[] = calcPosition(r);
  let rotations: Euler[] = calcRotate();

  return (
    <mesh position={[0, 0, 0]} >
        <mesh position={positions[0]} rotation={rotations[0]}>
          <BoardScene title={'南国の海'} verbiage={'熱帯魚と珊瑚礁'} image={'scene0.jpg'} movie={'/scene0.mp4'} color={'green'} index={0} description={'さあ、体の力を抜いて\n南国の海に\n身を任せましょう'} align={'left'} />
        </mesh>
        <mesh position={positions[1]} rotation={rotations[1]}>
          <BoardScene title={'星空'} verbiage={'天の川と北極星'} image={'scene1.jpg'} movie={'/scene1.mp4'} color={'blue'} index={1} description={'星に手が届くように\nもっと思いっきり\n体を伸ばしましょう'} align={'left'} />
        </mesh>
        {/* <mesh position={positions[2]} rotation={rotations[2]}>
          <BoardScene title={'AAA'} verbiage={'BBB'} image={'scene2.jpg'} movie={'/scene2.mp4'} color={'purple'} index={2} description={'Jet stream.'}/>
        </mesh>
        <mesh position={positions[3]} rotation={rotations[3]}>
          <BoardScene title={'AAA'} verbiage={'BBB'} image={'scene3.jpg'} movie={'/scene3.mp4'} color={'purple'} index={3}/>
        </mesh>
        <mesh position={positions[4]} rotation={rotations[4]}>
          <BoardScene title={'AAA'} verbiage={'BBB'} image={'scene4.png'} movie={'/scene4.mp4'} color={'purple'} index={4}/>
        </mesh>
        <mesh position={positions[5]} rotation={rotations[5]}>
          <BoardScene title={'AAA'} verbiage={'BBB'} image={'scene5.png'} movie={'/scene5.mp4'} color={'purple'} index={5}/>
        </mesh>
        <mesh position={positions[6]} rotation={rotations[6]}>
          <BoardScene title={'AAA'} verbiage={'BBB'} image={'scene6.png'} movie={'/scene6.mp4'} color={'purple'} index={6}/>
        </mesh>
        <mesh position={positions[7]} rotation={rotations[7]}>
          <BoardScene title={'AAA'} verbiage={'BBB'} image={'scene7.png'} movie={'/scene7.mp4'} color={'purple'} index={7}/>
        </mesh>
        <mesh position={positions[8]} rotation={rotations[8]}>
          <BoardScene title={'AAA'} verbiage={'BBB'} image={'scene8.png'} movie={'/scene8.mp4'} color={'purple'} index={8}/>
        </mesh>
        <mesh position={positions[9]} rotation={rotations[9]}>
          <BoardScene title={'AAA'} verbiage={'BBB'} image={'scene9.png'} movie={'/scene9.mp4'} color={'purple'} index={9}/>
        </mesh> 
        <mesh position={positions[10]} rotation={rotations[10]}>
          <BoardScene title={'AAA'} verbiage={'BBB'} image={'scene10.jpg'} movie={'/scene10.mp4'} color={'red'} index={10} description={'Hey!\nLet\'s do meditation on beach side.'}/>
        </mesh> */}
        <mesh position={positions[11]} rotation={rotations[11]}>
          <BoardScene title={'空と海と'} verbiage={'朝日の見える丘'} image={'scene11.jpg'} movie={'/scene11.mp4'} color={'orange'} index={11} description={'おはようございます\n\n朝日を眺めて\n元気を補給しましょう'} align={'left'} />
        </mesh>
      </mesh>
    );
  };

function calcPosition(r: number) {
  let positions: Vector3[] = [];

  for(let i=0; i<12; i++) positions.push([r*Math.sin(2*Math.PI/12*(6-i)), 0, r*Math.cos(2*Math.PI/12*(6-i)) ]);

  return positions;
}

function calcRotate() {
  let rotations: Euler[] = [];

  for(let i=0; i<12; i++) rotations.push([0, 2*Math.PI/12*(-i), 0]);

  return rotations;
}
  
export default Cards;
