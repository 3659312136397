import * as THREE from "three";
import { Color, useLoader } from "react-three-fiber";
import { TextureLoader } from 'three/src/loaders/TextureLoader'

export const BoxWithRoundedEdges = (args:any) => {
  const image: string = args.image;
  const backgroundColor: Color = args.color
  const texture = useLoader(TextureLoader, image)
  
  const width = args.width/2;
  const height = args.height/2;
  const rate = 0.95;

const shape: any = new THREE.Shape()
  .absarc( -width, -height, 1, -Math.PI / 2, -Math.PI, true )
  .absarc( -width, height , 1, Math.PI, Math.PI / 2, true )
  .absarc( width , height , 1, Math.PI / 2, 0, true )
  .absarc( width , -height, 1, 0, -Math.PI / 2, true );

  const extrudeSettings = {
    steps: 2,
    depth: args.depth,
    bevelEnabled: true,
    bevelThickness: 1,
    bevelSize: 1,
    bevelOffset: 0,
    bevelSegments: 10
  };

  return (
    <mesh>
      <mesh>
        <extrudeBufferGeometry attach="geometry" args={[shape, extrudeSettings]}/>
        <meshStandardMaterial attach='material' color={"white"} side={THREE.DoubleSide}/>
      </mesh>
      <mesh position={[0,0,2]}>
        {/* <extrudeBufferGeometry attach="geometry" args={[shapeInside, extrudeSettings]}/> */}
        <boxBufferGeometry args={[width*2*rate,height*2*rate,1]}/>
        <meshBasicMaterial color={0xffffff} map={texture} side={THREE.DoubleSide}/>
      </mesh>
      <mesh position={[0,0,-2]} rotation={[0, 0, 0]}>
        {/* <extrudeBufferGeometry attach="geometry" args={[shapeInside, extrudeSettings]}/> */}
        <boxBufferGeometry args={[width*2*rate,height*2*rate,1]}/>
        <meshBasicMaterial attach='material' color={backgroundColor} side={THREE.DoubleSide}/>
      </mesh>
    </mesh>
  );
};
