import React from "react";

const FormPage: React.FC = () => {

  return (
    <div
    style={{
      position: "absolute",
      top: 0,
      width: "100%",
      height: "100%",
      overflow: "scroll",
      margin: "0 auto",
    }}
    >
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSddRDy30lRJq4WQbBM_nhw_wTwysqR8B-kj-nix2K02oKL3RA/viewform?usp=sf_link" width="100%" height="100%" frameBorder="0" marginHeight={0} marginWidth={0}>読み込んでいます…</iframe>
    </div>
  );
};

export default FormPage;