import * as THREE from 'three'
import { useRef } from "react";
import { useFrame, useLoader } from "react-three-fiber";
import Star from './Star'

const Dome = () => {

  const r: number = 400;
  const cube = useRef<any>();

  useFrame(() => {
    const sec = performance.now() / 30000;
    cube.current!.rotation.y = sec;
  });
  
    return (
      <>
        <mesh>
          <sphereBufferGeometry attach="geometry" args={[r, 32, 32, 0, Math.PI *2, 0, Math.PI /2]}   />
          <meshStandardMaterial color={0x000000}  side={THREE.DoubleSide}/>
        </mesh>
        <mesh ref={cube}>
          <Star /> 
        </mesh>
      </>
  );
  };
  
export default Dome;
