import * as THREE from 'three'
import { useRef } from "react";
import { useFrame, useLoader } from "react-three-fiber";
import { TextureLoader } from 'three/src/loaders/TextureLoader'

const Star = () => {

  const r = 200;

    function createStars() {
        let star = []
        for (let i = 0; i < 1000; i++) {
          const x = (Math.floor(Math.random() * 2 * r) -r);
          const y = (Math.floor(Math.random() * 2 * r) -r);
          const z = (Math.floor(Math.random() * 2 * r) -r);
          star[i] = [x, y, z]
        }
        return star
      }
    
    const stars = createStars().map((cords: any, i) =>
      (<mesh position={cords} key={i}>
        <sphereBufferGeometry attach='geometry' args={[0.1, 32, 32]} />
        <meshStandardMaterial attach='material' color={"white"}/>
      </mesh>)
    )

    return (
      <>
        {stars}
      </>
      );
  }

  export default Star;