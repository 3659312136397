import * as THREE from 'three'
import { useRef, useState, useContext, useEffect } from "react";
import { useFrame } from "react-three-fiber";
import Cards from "./Cards"
import { AppCtx } from "../MainPage"

const Scene = () => {

  const appContext = useContext(AppCtx);
  const video = Object.assign(document.createElement("video"), {
    src: appContext?.state.movie,
    crossOrigin: "Anonymous",
    playsInline: true,
    loop: true,
    muted: true,
  })
  let playPromise = video.play();

  // In browsers that don’t yet support this functionality,
  // playPromise won’t be defined.
  if (playPromise !== undefined) {
    playPromise.then(function() {
      // Automatic playback started!
    }).catch(function(error) {
      console.log(error)
      // Automatic playback failed.
      // Show a UI element to let the user manually start playback.
    });
  }

  const meshRef = useRef<any>();

  useEffect(() => {
    meshRef.current!.opacity =0;
  },[appContext?.state.movie])

  const texture = new THREE.VideoTexture(video);
  useFrame(() => {
    if(meshRef.current!.opacity<=1) {
      meshRef.current!.opacity += 0.05;
    }
  });
  
    return (
      <mesh>
        <sphereBufferGeometry attach="geometry" args={[400, 32, 32,]}   />
        <meshBasicMaterial color="#ffffff" map={texture} toneMapped={false} side={THREE.DoubleSide} transparent ref={meshRef}/>
        {/* <meshBasicMaterial color={0xffffff} transparent map={texture} side={THREE.DoubleSide}/> */}
        <mesh position={[0, 0, 0]}>
          <Cards />
        </mesh>
      </mesh>
    );
  };
  
export default Scene;
