import Dome from './Dome'
import Cylinder from './Cylinder';
import Scene from './Scene'
import { useRef, useContext } from "react";
import { useFrame } from "react-three-fiber";
import { BoardTop } from "./BoardTop";
import { AppCtx } from "../MainPage"

let current: number = 0;

export const World = () => {

    const appContext = useContext(AppCtx);
    const level = useRef<any>();

    useFrame(() => {
        if(appContext?.state.init && level.current!.position.y < 600){
            const sec = performance.now() / 10000;
            if(current === 0) current = sec;
            level.current!.position.y += (sec - current) * 6.0;
        }
      });

  return (
    <mesh ref={level}>
        <mesh position={[0, 0, 0]}>
            <mesh position={[0, 0, -150]} scale={[0.2,0.2,0.2]}>
                <BoardTop />
            </mesh>
            <Dome />
        </mesh>

        <mesh position={[0, -300, 0]}>
            <Cylinder />
        </mesh>
        <mesh position={[0, -620, 0]}>
            <Scene />
        </mesh>
    </mesh>
  );
}
